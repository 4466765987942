import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IScope } from 'angular';
import { WINDOW } from '../../Shared/app/core/services/window.service';


@Component({
    selector: 'wfp-app',
    templateUrl: 'app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    environment: string;
    router: any;

    constructor(
        private _route: Router,
        @Inject(WINDOW) private window: any) {

        this.router = _route.url;        
    } 
    
    ngOnInit(): void { 
        this.environment = this.window.document.wfEnvironment;
     }
}

export interface IEnviromentScope extends IScope {
    wfEnvironment: string;
}
