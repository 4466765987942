import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AccessibilityStatementComponent } from '../../Shared/app/forms/accessibility-statement/accessibility-statement.component';
import { LogoutComponent} from "./logout/logout.component";

const routes: Routes = [
    { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
    { path: 'overview/:bookingRequestId', loadChildren: () => import('./overview/booking-overview.module').then(m => m.BookingOverviewModule) }, 
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: ':bookingId/:bookedFormId/mapped', loadChildren: () => import('./perform-form-mapped/perform-form-mapped.module').then(m => m.PerformFormMappedModule)},
    { path: ':bookingId/:formId/:bookedFormId/dynamic', loadChildren: () => import('./perform-form-container/perform-form-container.module').then(m => m.PerformFormContainerModule), data: {renderType: 'dynamic'}},
    { path: 'accessibilitystatement', component: AccessibilityStatementComponent },
    { path: 'logoutidp', component: LogoutComponent},
    { path: '**', redirectTo: 'login'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
    providers: [
        { provide: LocationStrategy, useClass: PathLocationStrategy },
    ]
}) 
export class AppRoutingModule { }
